import { render, staticRenderFns } from "./addThirduser.vue?vue&type=template&id=7de832dc&scoped=true"
import script from "./addThirduser.vue?vue&type=script&lang=js"
export * from "./addThirduser.vue?vue&type=script&lang=js"
import style0 from "./addThirduser.vue?vue&type=style&index=0&id=7de832dc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de832dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\工作\\项目\\智慧安全-2024\\sinoma-hse-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7de832dc')) {
      api.createRecord('7de832dc', component.options)
    } else {
      api.reload('7de832dc', component.options)
    }
    module.hot.accept("./addThirduser.vue?vue&type=template&id=7de832dc&scoped=true", function () {
      api.rerender('7de832dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/training/recording/components/addThirduser.vue"
export default component.exports