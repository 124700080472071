<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-02-22 16:52:09
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false"
             :dataForm.sync="dataForm"
             :visible.sync="showDialog"
             width="60%" class="qmDialog" @close="closeDialog">
    <avue-form :option="options" ref="addForm" v-model="form" @submit="treeNodeSave">
      <template slot="userName">
        <el-autocomplete placeholder="请输入姓名" v-model="form.userName" :fetch-suggestions="querySearch"
                         :trigger-on-focus="false" @select="handleSelect" maxlength="50">
        </el-autocomplete>
      </template>
      <template slot="idCoding">
        <el-autocomplete placeholder="请输入身份证号" v-model="form.idCoding" :fetch-suggestions="queryCodeSearch"
                         :trigger-on-focus="false" @select="handleSelect">
        </el-autocomplete>
      </template>
      <template slot="birthday">
        <span>
          {{ form.idCoding && form.idCoding != '' ? getAge(form.idCoding) : '' }}
        </span>

      </template>
    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  add as prjthirduserSubmit
} from "@/api/training/recording";
import {
  getList
} from "@/api/safe/thirduser";
import {
  getAgeFromIdCard,
  getBirthDate
} from "@/util/util";
import {
  PATTERN_ID_CARD
} from "@/util/validate"

export default {
  data() {
    return {
      showDialog: false,
      dialogLoading: false,
      treeReadOnlyFlag: false,
      form: {},
      dataForm: {},
      title: '',
      isEdit: false,
      searchData: [],
      pageType: ''
    }

  },
  computed: {
    options() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 140,
        column: [{
          label: "姓名",
          prop: "userName",
          rules: [{
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }]
        },
          {
            label: "身份证号",
            prop: "idCoding",
            rules: [{
              required: true,
              validator: this.validateIdCard,
              trigger: 'blur'
            }]
          },
          {
            label: "性别",
            prop: "sex",
            type: "radio",
            dicData: [{
              label: '男',
              value: 1
            },
              {
                label: '女',
                value: 2
              },
              {
                label: '未知',
                value: 3
              },
            ],
            rules: [{
              required: true,
              message: "请选择性别",
              trigger: "blur"
            }]
          },
          {
            label: "年龄",
            prop: "birthday",
            rules: [{
              required: true,
              message: "请输入年龄",
              trigger: "blur"
            }]
          },
          {
            label: "籍贯",
            prop: "domicile",
            rules: [{
              required: true,
              message: "请输入籍贯",
              trigger: "blur"
            }]
          },
          {
            label: "工种",
            prop: "post",
            rules: [{
              required: true,
              message: "请输入工种",
              trigger: "blur"
            }]
          },
          {
            label: '是否启用',
            prop: 'isUsed',
            type: 'switch',
            value: true
          },
        ],
      }
    }
  },

  methods: {
    init(type, data, companyId) {
      this.form = {}
      this.showDialog = true
      this.treeReadOnlyFlag = false;
      this.pageType = type
      if (type == 'edit') {
        this.title = '编辑相关方人员'
        this.form = {
          ...data
        }
        this.form.isUsed = this.form.isUsed === 1 ? false : true
      } else {
        this.title = '新增相关方人员'
        this.form.prjId = data
        this.form.companyId = companyId
      }

    },
    // 身份证校验
    validateIdCard(rule, value, callback) {
      if (value == '') {
        callback(new Error('请填写身份证号码'));
      } else {
        let valid = PATTERN_ID_CARD.test(value)
        if (valid) {
          callback();
        } else {
          callback(new Error('请填写正确的身份证号码'));
        }
      }

    },
    //输入查询
    querySearch(queryString, cb) {
      let result = []
      this.isEdit = false
      if (queryString == '') {
        cb(result)
      } else {
        Promise.all([this.getListInfo(queryString, 'userName')]).then(() => {
          cb(this.searchData);
        })
        // 调用 callback 返回建议列表的数据
      }
    },
    queryCodeSearch(queryString, cb) {
      let result = []
      this.isEdit = false
      if (queryString == '') {
        cb(result)
      } else {
        Promise.all([this.getListInfo(queryString, 'idCoding')]).then(() => {
          cb(this.searchData);
        })
        // 调用 callback 返回建议列表的数据
      }
    },
    getListInfo(queryString, type) {
      this.query = {
        userName: queryString
      }
      return new Promise((r) => {
        getList(1, 20, this.query).then((res) => {
          let data = res.data.data.records
          let hasUseData = []
          if (data.length) {
            data.map((item) => {
              if (item.isUsed != 1) {
                hasUseData.push({
                  ...item,
                  value: type == 'idCoding' ? item.idCoding : item.userName
                })
              }
            })
            this.searchData = hasUseData
          } else {
            this.searchData = []
          }
          r()
        })
      })
    },
    getAge(data) {
      this.form.birthday = getBirthDate(data)
      return getAgeFromIdCard(data)
    },

    handleSelect(item) {
      this.form.userName = item.userName
      this.form.idCoding = item.idCoding
      this.form.sex = item.sex
      this.form.birthday = getBirthDate(item.idCoding)
      this.form.domicile = item.domicile
      this.form.post = item.post
      this.form.userId = item.id
      this.form.isUsed = item.isUsed === 1 ? false : true
      this.form.isManual = true
      this.isEdit = true

    },

    handleTreeNodeSave(data) {
      this.$refs.addForm.submit();
    },
    treeNodeSave(data) {
      data.isUsed = data.isUsed == true ? 0 : 1
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          let age = this.getAge(this.form.idCoding)
          if (age <= 0) {
            this.$message({
              type: "warning",
              message: "年龄不能小于0",
            });
            done()
            return;
          }
          if (this.pageType != 'edit') {
            data.id = ''
          }
          this.dialogLoading = true
          prjthirduserSubmit(data).then(res => {
            const {
              msg,
              data
            } = res.data;
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            console.log(res.data.data, "callback")
            this.$emit('callback', res.data.data)
            this.dataForm = {}
            done()
            this.showDialog = false;
            this.dialogLoading = false
          }).catch(err => {
            done()
          }).finally(() => {
            done()
            this.dialogLoading = false
          })
        }
      })
    },
    closeDialog() {
      this.form = {}
      this.searchData = []
      this.$refs.addForm.resetFields();
      this.showDialog = false
    },
  }
}

</script>
<style lang="scss" scoped>
.el-autocomplete {
  width: 100%
}

</style>
