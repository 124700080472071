var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog",
      attrs: {
        title: _vm.title,
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        dataForm: _vm.dataForm,
        visible: _vm.showDialog,
        width: "60%",
      },
      on: {
        "update:dataForm": function ($event) {
          _vm.dataForm = $event
        },
        "update:data-form": function ($event) {
          _vm.dataForm = $event
        },
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "avue-form",
        {
          ref: "addForm",
          attrs: { option: _vm.options },
          on: { submit: _vm.treeNodeSave },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "userName" },
            [
              _c("el-autocomplete", {
                attrs: {
                  placeholder: "请输入姓名",
                  "fetch-suggestions": _vm.querySearch,
                  "trigger-on-focus": false,
                  maxlength: "50",
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "idCoding" },
            [
              _c("el-autocomplete", {
                attrs: {
                  placeholder: "请输入身份证号",
                  "fetch-suggestions": _vm.queryCodeSearch,
                  "trigger-on-focus": false,
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.form.idCoding,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "idCoding", $$v)
                  },
                  expression: "form.idCoding",
                },
              }),
            ],
            1
          ),
          _c("template", { slot: "birthday" }, [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.form.idCoding && _vm.form.idCoding != ""
                      ? _vm.getAge(_vm.form.idCoding)
                      : ""
                  ) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.closeDialog },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleTreeNodeSave },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }